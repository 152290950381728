<template>
   <navi-bar>
     <img src="@/assets_mobile/img/icon_customer.png"/>
     <span>{{$t('front.common.notice')}}<em>Notice</em></span>
   </navi-bar>
  <section class="sub-box">
    <cs-nav-mobile/>
    <board-filter-mobile @onSearch="onSearch"/>
    <div class="writebtn">
<!--      <select class="select">-->
<!--        <option>공지분류</option>-->
<!--        <option>전체</option>-->
<!--        <option>라이브카지노</option>-->
<!--        <option>슬롯</option>-->
<!--        <option>충전/환전</option>-->
<!--        <option>베팅관련</option>-->
<!--        <option>기타</option>-->
<!--      </select>-->
    </div>
    <div class="msgwrap qnalistwrap">
      <template v-for="item in list" v-bind:key="item.boardIdx">
        <ul>
          <li>
            <a @click="onClickRead(item.boardIdx)"><!-- @click="onClickRead(item.boardIdx)" -->
              <span class="date"><em class="waiticon">{{$t('front.boardCategory.' + item.category)}}</em>{{item.regDate}}</span>
              <span class="subject">{{item.title}}<em :class="{ new: item.isNew === 'Y' }" class="old newicon">N</em></span>
            </a>
            <!--span class="arrow" @click="item.isContentOpen = !item.isContentOpen">
              <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="item.isContentOpen"/>
              <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!item.isContentOpen"/>
            </span><-- icon_bottom_gr -->
          </li>
          <!--li class="qnalistread progress"  v-if="item.isContentOpen">
            <p class="writing mb45">{{ item.content }}</p>
          </li-->
        </ul>
      </template>
    </div>

    <!--div class="searchwrap">
      <select class="searchselect">
        <option>제목</option>
        <option>날짜</option>
        <option>글쓴이</option>
      </select>
      <input class="searchinput" type="text" placeholder="검색어를 입력해 주세요.">
      <a class="searchicon"></a>
    </div-->
    <pagination-mobile v-if="pageInfo"
                       :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList()"
                       />
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import CsNavMobile from '@/views_mobile/member/cs/nav'
import Notice from '@/views/member/cscenter/notice/list'
import BoardFilterMobile from '@/components/common/mobile/BoardFilterMobile'
export default {
  name: 'Coupon',
  components: {
    CsNavMobile,
    NaviBar,
    PaginationMobile,
    BoardFilterMobile
  },
  mixins: [Notice]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
